import React, { useEffect, memo } from "react";
import { Route } from "react-router-dom";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import "./App.css";

import Landing from "./Views/LandingPage.js";
import ProductSelection from "./Views/ProductSelection.js";
import QtySelection from "./Views/QtySelection";
import TimeSlot from "./Views/TimeSlotSelection";
import Upgrades from "./Views/Upgrades";
import Cart from "./Views/Cart";
import Checkout from "./Views/Checkout";
import ThankYou from "./Views/ThankYou";
import ZipCode from "./Views/ZipCode";
import IdleTimeOutModal from "./Components/IdleTimeOutModal";
import Exchange from "./Views/Exchange";
import Printing from "./Views/Printing";
import Loading from "./Components/Loading";

import {
  isPrinterOnline,
  setApiData,
  updatePaymentSocket,
} from "./Actions/actionCreator";
import { bgImages } from "./Utils/Helpers/getStyleOrResources";

import {
  GlobalStyle,
  AppWrapper,
  LoadingWrapper,
} from "./Styles/ViewStyles/AppStyles";

import usePaymentSocket from "./Providers/PrinterProvider/usePaymentSocket";
import history from "./Actions/history";

const PaymentSocketComponent = memo(function PaymentSocketComponent({
  updatePaymentSocket,
  paymentWebSocket,
}) {
  const connection = usePaymentSocket();

  useEffect(() => {
    if (
      paymentWebSocket?.isOnline !== connection?.isOnline ||
      connection.lastMessage !== paymentWebSocket?.lastMessage
    ) {
      updatePaymentSocket(connection);
    }
    if (!connection?.isOnline) {
      history.push("/");
    }
  }, [connection?.isOnline, connection?.lastMessage]);

  return null;
});

class App extends React.Component {
  constructor(props) {
    super(props);
    const entries = performance.getEntriesByType("navigation");
    const onRefresh = entries.map((nav) => nav.type);
    if (onRefresh[0] === "reload") {
      window.location = "/";
    }
    this.state = {
      timeout: 25000,
      showModal: false,
      isTimedOut: false,
      appWrapperBgImg: null,
    };
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);

    this.checkStatus = () => {
      const status = sessionStorage.getItem("isPrinterOnline");
      this.printerOnline = status === "true";
      this.props.isPrinterOnline(this.printerOnline);
      console.log("check status printerOnline = " + this.printerOnline);
      setTimeout(() => {
        this.checkStatus();
      }, 1000);
    };
  }

  _onAction() {
    if (!this.state.showModal) {
      this.setState({ isTimedOut: false });
    }
  }

  _onActive() {
    this.setState({ isTimedOut: false });
  }

  _onIdle() {
    const { isTimedOut } = this.state;
    if (isTimedOut) {
      this.props.history.push("/");
      this.setState({ showModal: false });
    } else {
      this.setState({ showModal: true });
      this.idleTimer?.pause();
      this.setState({ isTimedOut: true });
    }
  }

  // This is the action taken on the idle modal when the "Yes (I need more time)" button is clicked
  handleClose = () => {
    this.setState({ showModal: false });
    this.idleTimer?.reset();
  };

  // This is the action taken on the idle modal when the "No (I don't need more time)" button is clicked
  // It resets the store, heads back to the Landing page, and removes the modal
  handleStartOver = () => {
    if (this.props.orderId) {
      const payload = {
        type: "cancelPayment",
        data: { orderId: this.props.orderId },
      };
      this.props.paymentWebSocket.handleSendPaymentMessage(
        JSON.stringify(payload)
      );
    }
    this.props.history.push("/");
    this.printerOnline = sessionStorage.getItem("isPrinterOnline") === "true";
    this.props.isPrinterOnline(this.printerOnline);

    this.setState({ showModal: false, isTimedOut: false });
  };

  componentDidMount() {
    window.onbeforeunload = function () {
      window.setTimeout(function () {
        window.location = "/";
      }, 0);
      window.onbeforeunload = null; // necessary to prevent infinite loop, that kills your browser
    };
    this.props.setApiData();
    this.checkStatus();
  }

  render() {
    const { client, API, bocaStatusUrl, printerSocketUrl, history } =
      this.props;
    const { showModal } = this.state;
    const mainPage = history.location.pathname === "/";
    const isBgImageAbsent = client === "cgg" && !mainPage;
    const isIdleTimerEnabled =
      !mainPage && history.location.pathname !== "/ZipCode";
    const bgImg = isBgImageAbsent ? "" : bgImages[client];
    const configLoadedSuccessfuly = [
      client,
      API,
      bocaStatusUrl,
      printerSocketUrl,
    ].every(Boolean);

    if (!configLoadedSuccessfuly) {
      return (
        <LoadingWrapper>
          <Loading isFull={false} />
        </LoadingWrapper>
      );
    }

    return (
      <AppWrapper client={client} bgImg={bgImg}>
        <GlobalStyle client={client} />
        <PaymentSocketComponent
          updatePaymentSocket={this.props.updatePaymentSocket}
          paymentWebSocket={this.props.paymentWebSocket}
        />
        {isIdleTimerEnabled && (
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout}
          />
        )}
        {showModal && (
          <IdleTimeOutModal
            handleClose={this.handleClose}
            handleStartOver={this.handleStartOver}
            showModal={this.state.showModal}
          />
        )}
        <Route exact path="/" component={Landing} />
        <Route path="/CityPassExchange" component={Exchange} />
        <Route path="/ProductSelection" component={ProductSelection} />
        <Route path="/QtySelection" component={QtySelection} />
        <Route path="/EntryTime" component={TimeSlot} />
        <Route path="/Upgrades" component={Upgrades} />
        <Route path="/Cart" component={Cart} />
        <Route path="/Checkout">
          <Checkout idleTimer={this.idleTimer} handleClose={this.handleClose} />
        </Route>
        <Route path="/ZipCode" component={ZipCode} />
        <Route path="/Printing" component={Printing} />
        <Route path="/ThankYou" component={ThankYou} />
      </AppWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    boca: state.bocaStatusWebSocket,
    printerOnline: state.printerOnline,
    client: state.client,
    API: state.API,
    bocaStatusUrl: state.bocaStatusUrl,
    printerSocketUrl: state.printerSocketUrl,
    fstate: state,
    paymentWebSocket: state.paymentWebSocket,
    orderId: state?.orderStatus?.orderId,
  };
};

const mapDispatchToProps = {
  isPrinterOnline,
  setApiData,
  updatePaymentSocket,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
