import styled from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

const ButtonTemplate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  color: ${(props) => getStyle(props.client).fontColor};
  transition-duration: 500ms;
  transform: rotateX(0deg);
  background-color: ${(props) =>
    props.second
      ? getStyle(props.client).secondary
      : props.client === "cgg"
        ? getStyle(props.client).primaryOrange
        : getStyle(props.client).primary};

  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.second
        ? getStyle(props.client).secondaryHover
        : getStyle(props.client).primaryHover};
  }
`;

export const Button = styled(ButtonTemplate)`
  width: ${(props) => props.width || "150px"};
  height: ${(props) => props.height || "45px"};
  max-height: 80px;
  min-height: 28px;

  h6 {
    display: flex;
    align-items: center;
    font-family: ${(props) =>
      props.client !== "cgg" ? "gothamBold" : getStyle(props.client).font};
    font-size: 1.2em;
    margin: 0;
  }
`;

export const LongButton = styled(ButtonTemplate)`
  width: ${(props) => props.width || "520px"};
  height: ${(props) => (props.client === "cgg" ? "85px" : "95px")};
  max-height: 150px;

  margin-bottom: 16px;
  transition-duration: 500ms;
  transform: rotateX(5deg);

  h2 {
    font-family: ${(props) =>
      props.client !== "cgg"
        ? getStyle(props.client).boldFont
        : getStyle(props.client).font};
    font-size: ${(props) => (props.client === "cgg" ? "1.8em" : "2.4em")};
    margin: 0;
    display: flex;
    align-items: center;
  }

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
`;

export const TriangleSN = styled.span`
  margin: 0 10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 12px solid #fff;
`;

export const TriangleCGG = styled.span`
  margin: 0 6px 0 12px;
  display: block;
  width: 12px;
  height: 12px;
  border-top: 4px solid #fff;
  border-left: 4px solid #fff;
  transform: rotate(-45deg);
`;
