import {
  PAYMENT_ERRORS_BY_CODE,
  PAYMENT_ERRORS_BY_MESSAGE,
} from "../../Consts/payment.consts";

export const getPaymentMessageError = (code, message) => {
  const errorByCode = code ? PAYMENT_ERRORS_BY_CODE?.[code] : null;
  const errorByMessage = message ? PAYMENT_ERRORS_BY_MESSAGE?.[message] : null;
  return errorByCode || errorByMessage || "The transaction was declined.";
};
