import styled from "styled-components";
import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

export const CheckoutLoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  h3 {
    color: white;
    text-align: center;
    margin-bottom: 3%;
    font-family: ${(props) => getStyle(props.client).boldFont};
  }
`;

export const IconsWrapper = styled.div`
  width: 70%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  background: rgba(255, 255, 255, 0.8);
  clip-path: polygon(0% 0%, 100% 0%, 96% 80%, 50% 100%, 4% 80%);
`;

export const ImageTitle = styled.div`
  margin-bottom: 40px;
`;

export const IconsRow = styled.div`
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.center ? "center" : "space-between")};
  gap: 50px;

  & svg {
    max-width: ${(props) => (props.center ? "25%" : "none")};
    height: auto;

    &.smaller {
      color: green;
      transform: scale(0.7);
    }
  }
`;

export const CheckoutPaymentErrorModalWrapper = styled.div`
  min-height: 650px;
  margin: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

export const PaymentErrorTitle = styled.div`
  margin-bottom: 50px;
  font-size: 50px;
`;
