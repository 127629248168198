const getDate = () => {
  const now = new Date();

  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const year = String(now.getFullYear()).slice(-2);

  const formattedDate = `${month}${day}${year}`;

  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  const formattedTime = `${hours}${minutes}${seconds}`;

  return { formattedDate, formattedTime };
};

const getFakePaymentMessage = (amount, orderId, client) => {
  const { formattedDate, formattedTime } = getDate();

  return {
    saleRequestTransaction: {
      apiFields: [
        {
          fieldNumber: "0001",
          fieldValue: "02",
        },
        {
          fieldNumber: "0002",
          fieldValue: amount,
        },
        {
          fieldNumber: "0003",
          fieldValue: "ID:",
        },
        {
          fieldNumber: "0004",
          fieldValue: 1231,
        },
        {
          fieldNumber: "0006",
          fieldValue: "AUTH_CODE",
        },
        {
          fieldNumber: "0007",
          fieldValue: orderId,
        },
        {
          fieldNumber: "0013",
          fieldValue: formattedDate,
        },
        {
          fieldNumber: "0014",
          fieldValue: formattedTime,
        },
        {
          fieldNumber: "0047",
          fieldValue: `UAT_${client.toUpperCase()}_KIOSK`,
        },
        {
          fieldNumber: "0060",
          fieldValue: "00000999",
        },
        {
          fieldNumber: "0109",
          fieldValue: "UNATTEND",
        },
        {
          fieldNumber: "0110",
          fieldValue: "1137",
        },
        {
          fieldNumber: "0115",
          fieldValue: 10,
        },
        {
          fieldNumber: "0740",
          fieldValue: "",
        },
        {
          fieldNumber: "1001",
          fieldValue: "VISA/MASTERCARD",
        },
        {
          fieldNumber: "1003",
          fieldValue: 0,
        },
        {
          fieldNumber: "1004",
          fieldValue: "APPROVAL",
        },
        {
          fieldNumber: "1010",
          fieldValue: "COMPLETE",
        },
        {
          fieldNumber: "5002",
          fieldValue: "52875369",
        },
        {
          fieldNumber: "8002",
          fieldValue: "SPACENEEDLE",
        },
        {
          fieldNumber: "8006",
          fieldValue: "TSTLA3",
        },
      ],
    },
  };
};

export default getFakePaymentMessage;
