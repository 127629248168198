import { useState, useEffect, useRef } from "react";

const WS_PAYMENT_URL = "http://localhost:8081";

const ONLINE_MSG = "online";
const OFFLINE_MSG = "offline";
const statusMessages = [ONLINE_MSG, OFFLINE_MSG];

const usePaymentSocket = () => {
  const [paymentSocket, setPaymentSocket] = useState(null);
  const [isOnline, setIsOnline] = useState(false);
  const [lastMessage, setLastMessage] = useState("");
  const paymentRef = useRef(null);

  const connectWebSocket = (
    prevSocket,
    url,
    setSocket,
    setOnlineStatus,
    setLast
  ) => {
    const ws = new WebSocket(url);

    ws.onopen = () => {
      if (setOnlineStatus) {
        setOnlineStatus(true);
      }
      console.log(`Payment WebSocket connected to ${url}`);
    };
    ws.onclose = () => {
      prevSocket?.close();
      setSocket(null);
      if (setOnlineStatus) {
        setOnlineStatus(false);
      }
      setTimeout(() => {
        connectWebSocket(prevSocket, url, setSocket, setOnlineStatus);
      }, 2000);
    };
    ws.onmessage = (e) => {
      try {
        if (setOnlineStatus) {
          const content = JSON.parse(e.data);
          const message = content?.message;
          if (statusMessages.includes(message)) {
            setOnlineStatus(message === ONLINE_MSG);
            return;
          }
          setLast(content);
        }
      } catch (error) {
        console.error(
          "Error on payment socket msg read: ",
          JSON.stringify(error)
        );
      }
    };

    setSocket(ws);
  };

  useEffect(() => {
    connectWebSocket(
      paymentSocket,
      WS_PAYMENT_URL,
      setPaymentSocket,
      setIsOnline,
      setLastMessage
    );

    return () => {
      if (paymentSocket) {
        paymentSocket.close();
        setPaymentSocket(null);
      }
    };
  }, []);

  useEffect(() => {
    paymentRef.current = paymentSocket;
  }, [paymentSocket]);

  const handleSendPaymentMessage = (message) => {
    try {
      if (isOnline) {
        paymentRef.current?.send(message);
      }
    } catch (error) {
      console.error("Payment message sent error: ", error);
    }
  };

  const resetMessage = () => {
    setLastMessage("");
  };

  return {
    isOnline,
    handleSendPaymentMessage,
    lastMessage,
    resetMessage,
  };
};

export default usePaymentSocket;
