import axios from "axios";

import * as actions from "./actions";

import {
  datePrettier,
  addDays,
  apiQueryDate,
} from "../Utils/Helpers/dateHelpers";
import { getMultiTimedProductDto } from "../Utils/Helpers/helpers";
import { getResources } from "../Utils/Helpers/getStyleOrResources";
import cloneDeep from "../Utils/Helpers/cloneDeep";
import history from "./history";
import { getMultiTimedProductTotalPrice } from "../Utils/Helpers/helpers";

import { PrinterWebSocket } from "../Utils/_PrinterWebSocket/webSocket";
import {
  bocaStatusWebSocketOnMessage,
  ticketPrintWebSocketOnMessage,
} from "../Utils/Helpers/socketHelpers";
import { MemberVenueIdEnum, SalesTerminalTypeIdEnum } from "./clients";

const getKeys = (clientType) => {
  const resources = getResources(clientType);
  return resources;
};

export const getHeaders = (client, isForPrinter) => {
  const data = {
    MemberVenueId: MemberVenueIdEnum?.[client],
    SalesTerminalTypeId: SalesTerminalTypeIdEnum?.[client],
    LocalTimeZone: "Pacific Standard Time",
  };
  const stringifiedData = JSON.stringify(data);
  const appKey = btoa(stringifiedData);
  const headers = {
    headers: {
      "Content-Type": "application/json",
      appKey,
    },
  };

  return isForPrinter ? appKey : headers;
};

// Takes in a date and returns all products available on that date
export const fetchProductsList = (date, tab) => {
  const fetchDate = date || new Date();
  const urlDate = datePrettier(fetchDate);
  return async (dispatch, getState) => {
    const { client, API } = getState();
    const headers = getHeaders(client);
    dispatch({ type: actions.SET_IS_LOADING, payload: true });
    await axios
      .get(
        `${API}/api/kiosk/productsWithPromotions?visitDate=${urlDate}`,
        headers
      )
      .then(async (response) => {
        const { data: fullProductList } = response;
        const products = await changeProdList(
          fullProductList,
          tab || "1",
          true
        );
        dispatch({
          type: actions.PRODUCT_SUCCESS,
          payload: { fullProductList, productList: products },
        });
      })
      .then(() => {
        dispatch({ type: actions.SET_IS_LOADING, payload: false });
      })
      .catch((err) => console.error(err));
  };
};

export const checkCurrentProductListIsActual = () => {
  const fetchDate = new Date();
  const urlDate = datePrettier(fetchDate);
  return (dispatch, getState) => {
    const { client, API, fullProductList } = getState();
    const headers = getHeaders(client);
    dispatch({ type: actions.SET_IS_LOADING, payload: true });
    axios
      .get(
        `${API}/api/kiosk/productsWithPromotions?visitDate=${urlDate}`,
        headers
      )
      .then(async (response) => {
        const { data } = response;
        const currentListIds = fullProductList
          .map((product) => product.id)
          .sort()
          .toString();
        const newIds = data
          .map((product) => product.id)
          .sort()
          .toString();
        if (currentListIds !== newIds) {
          const products = await changeProdList(data, "1", true);
          dispatch({
            type: actions.PRODUCT_SUCCESS,
            payload: { fullProductList: data, productList: products },
          });
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        dispatch({ type: actions.SET_IS_LOADING, payload: false });
      });
  };
};

// Takes in a product ID and identifier and returns all available promotions or discounts
// Parameters: 1- product ID, 2- type identifier ('p'=promotions and 'd'=discounts)
export const fetchProductModifiers = (prodId, type) => {
  const typeId = {
    urlTag: {
      p: "promotions",
      d: "discounts",
    },
    actionType: {
      p: "PROMO_SUCCESS",
      d: "DISCOUNT_SUCCESS",
    },
  };

  return (dispatch, getState) => {
    const { client, API } = getState();
    const headers = getHeaders(client);
    const url = `${API}/v1/products/${prodId}/${typeId.urlTag[type]}`;
    axios.get(url, headers).then((response) => {
      dispatch({
        type: actions[typeId.actionType[type]],
        payload: response.data.response,
      });
    });
    // TODO add a catch
  };
};

// Takes in a product ID and date and provides available timeslots for timed ticket purchases
export const fetchProductAvailTimes = (prodId, date) => {
  if (prodId === 0) {
    const start = datePrettier(date);
    const end = datePrettier(addDays(date));

    return (dispatch, getState) => {
      const { client, API, isExchange } = getState();
      const headers = getHeaders(client);
      const url = `${API}/api/kiosk/product/${prodId}/inventory?startDate=${start}&endDate=${end}`;

      dispatch({
        type: actions.SET_IS_LOADING,
        payload: true,
      });

      axios
        .get(url, headers)
        .then(() => {
          const availTimes = {
            slotIds: [],
            slotStructPriceAdjustmentModels: [],
          };

          dispatch({
            type: actions.PROD_TIMES_SUCCESS,
            payload: availTimes,
          });

          // to fix handler
          if (isExchange && !availTimes.productInventoryModels.length) {
            dispatch(
              setError(
                "There are no time slots available for today. Please choose a different date for your visit using the calendar above."
              )
            );
            return;
          }

          if (isExchange) {
            dispatch(redirectUser("/EntryTime"));
          }
        })
        .catch((err) => {
          console.error("fetchProductAvailTimes error\n", err);
          dispatch(
            setError(
              "Error while trying to get a list of available time slots."
            )
          );
        })
        .finally(() => {
          dispatch({
            type: actions.SET_IS_LOADING,
            payload: false,
          });
        });
    };
  } else {
    const start = datePrettier(date);
    const end = datePrettier(addDays(date));

    return (dispatch, getState) => {
      const { client, API, isExchange, currentProduct } = getState();
      const headers = getHeaders(client);
      const url = `${API}/api/kiosk/product/${prodId}/inventory?startDate=${start}&endDate=${end}`;

      dispatch({ type: actions.SET_IS_LOADING, payload: true });
      axios
        .get(url, headers)
        .then((response) => {
          const times = listDayParts(response.data, currentProduct);
          dispatch({
            type: actions.PROD_TIMES_SUCCESS,
            payload: times,
          });

          // to fix error handler
          if (isExchange && !times.productInventoryModels.length) {
            dispatch(
              setError(
                "There are no time slots available for today. Please choose a different date for your visit using the calendar above."
              )
            );
            return;
          }

          if (isExchange) {
            dispatch(redirectUser("/EntryTime"));
          }
        })
        .catch((err) => {
          console.error("fetchProductAvailTimes error\n", err);
          dispatch(
            setError(
              "Error while trying to get a list of available time slots."
            )
          );
        })
        .finally(() => {
          dispatch({ type: actions.SET_IS_LOADING, payload: false });
        });
    };
  }
};

// Takes in the cart and returns the order ID/confirmation and total price
export const createPendingOrder = (cart) => {
  return (dispatch, getState) => {
    const { client, API, isExchange, totalTicketsPrice, currentProduct } =
      getState();
    const headers = getHeaders(client);
    cart.userAccountId = 1137;

    const cartDto = !currentProduct.isMultiTimed
      ? cart
      : getMultiTimedProductDto(cart);

    dispatch({ type: actions.SET_IS_LOADING, payload: true });
    axios
      .post(`${API}/api/kiosk/create-pending-order`, cartDto, headers)
      .then((response) => {
        const { data } = response;
        const { totalPrice, totalTax } = data;
        const totalPriceWithoutTaxes = +parseFloat(
          totalPrice - totalTax
        ).toFixed(2);

        const { timedYN } = currentProduct;
        if (
          !isExchange &&
          timedYN &&
          totalPriceWithoutTaxes !== totalTicketsPrice
        ) {
          throw new Error(
            "An unexpected error appeared while calculating total price."
          );
        }

        dispatch({
          type: actions.ORDER_SUCCESS,
          payload: response.data,
        });
        if (isExchange) {
          dispatch(redirectUser("/ZipCode"));
        }
      })
      .catch((err) => {
        dispatch({
          type: actions.ORDER_ERROR,
          payload: err.message || err,
        });
        dispatch(addTotalTicketsPrice(null));
      })
      .finally(() => {
        dispatch({ type: actions.SET_IS_LOADING, payload: false });
      });
  };
};

// Updates the Selected Date in Redux
export const changeDate = (date) => {
  return (dispatch) => {
    dispatch({ type: actions.DATECHANGE, payload: date });
  };
};

// Changes the productList to the correct subset of fullProductList
export const changeProdList = (fullList, tab, local = false) => {
  const main = tab !== "1";
  const products = fullList.filter((p) => {
    return p.exchangeProductYN === main;
  });
  if (local) {
    return products;
  }
  return (dispatch) => {
    dispatch({
      type: actions.PROD_CATEGORY_CHANGE,
      payload: { productList: products, currentTab: tab },
    });
  };
};

// Updates the Cart in Redux
export const addToCart = (newCart, oldCart) => {
  if (newCart.products.length) {
    const oldCartMod = { ...oldCart, products: cloneDeep(newCart.products) };
    return (dispatch) => {
      dispatch({ type: actions.ADD_TO_CART, payload: oldCartMod });
    };
  }
  return (dispatch) => {
    dispatch({ type: null });
  };
};

export const addToCartN = (newCart, oldCart) => {
  if (newCart.products[0]) {
    const id = newCart.products[0].productId;
    const otherProds = oldCart.products.filter((item) => {
      return item.productId !== id;
    });
    otherProds.push(...newCart.products);
    const oldCartMod = { ...oldCart, products: otherProds };
    return (dispatch) => {
      dispatch({ type: actions.ADD_TO_CART_N, payload: oldCartMod });
    };
  }
  return (dispatch) => {
    dispatch({ type: null });
  };
};

export const addTimesToCart = (cart, id, time) => {
  return (dispatch, getState) => {
    const { currentProduct, availTimes } = getState();
    const newCart = {
      orderId: cart.orderId,
      orderComment: cart.orderComment,
      products: [],
    };
    cart.products.forEach((t) => {
      if (currentProduct.isMultiTimed) {
        newCart.products.push({
          productId: id,
          appliedPromotions: t.appliedPromotions,
          time,
        });
        return;
      }
      if (t.productId === id) {
        newCart.products.push({
          productId: id,
          appliedPromotions: t.appliedPromotions,
          productInvId: time.id,
          date: time.start,
        });
        return;
      }

      newCart.products.push(t);
    });
    dispatch({ type: actions.ADD_TO_CART, payload: newCart });
    if (currentProduct.isMultiTimed) {
      const price = getMultiTimedProductTotalPrice(
        newCart,
        availTimes,
        currentProduct.retailPrice,
        currentProduct.promotions
      );
      dispatch(addTotalTicketsPrice(price));
    }
  };
};

// Updates the Selected Product in Redux
export const selectProduct = (prod) => {
  return (dispatch) => {
    dispatch({ type: actions.PRODUCT_SELECT, payload: prod });
  };
};

export const selectProductN = (prod) => {
  return (dispatch) => {
    dispatch({ type: actions.PRODUCT_SELECT_N, payload: prod });
  };
};

// Notifies the store to reset all back to initialState
export const resetState = () => {
  return (dispatch) => {
    dispatch({
      type: actions.RESET_STATE,
    });
  };
};

export const setApiData = () => {
  const clientBySubdomain = window.location.hostname.split(".")[0];

  const client =
    window.location.hostname === "localhost" ? "sn" : clientBySubdomain;

  return (dispatch) => {
    axios
      .get("/config.json")
      .then((res) => res.data)
      .then((config) => {
        const {
          REACT_APP_TICKET_URL: API,
          REACT_APP_BOCA_STATUS_URL: bocaStatusUrl,
          REACT_APP_PRINTER_SOCKET_URL: printerSocketUrl,
          REACT_APP_VIEW_TICKETS: viewTicketsLink,
          IS_DEBUG_MODE,
        } = config;

        const isDebugMode = IS_DEBUG_MODE === "true";

        dispatch({
          type: actions.SET_API_DATA,
          payload: {
            client,
            API,
            bocaStatusUrl,
            printerSocketUrl,
            viewTicketsLink,
            isDebugMode,
          },
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

// Notifies the store to reset the cart only
export const resetCart = () => {
  return (dispatch) => {
    dispatch({ type: actions.RESET_CART });
  };
};

export const resetOrder = () => {
  return (dispatch) => {
    dispatch({ type: actions.RESET_ORDER });
  };
};

// Changes the selected time for the product
export const selectTime = (productId, time) => {
  return (dispatch, getState) => {
    const { currentTime } = getState();
    dispatch({
      type: actions.TIME_SELECT,
      payload: productId ? { ...currentTime, [productId]: time } : {},
    });
  };
};

export const selectTimeN = (productId, time) => {
  return (dispatch, getState) => {
    const { currentTime } = getState();
    dispatch({
      type: actions.TIME_SELECT_N,
      payload: productId ? { ...currentTime, [productId]: time } : {},
    });
  };
};

export const resetSelectedTime = () => {
  return (dispatch) => {
    dispatch({ type: actions.RESET_TIME_SELECT });
  };
};

export const selectUpgrade = (value) => {
  return (dispatch) => {
    dispatch({ type: actions.SKIP_UPGRADE, payload: value });
  };
};

// Takes the available times and adds to it a list of sorted day parts
const listDayParts = (timesData, currentProduct) => {
  const times = cloneDeep(timesData);

  const invIds = times.productInventoryModels
    .map((slot) => slot.productId)
    .filter((value, index, self) => self.indexOf(value) === index);

  const slots = invIds.reduce((prev, cur) => {
    const productInventoryModelsById = timesData.productInventoryModels.filter(
      (obj) => obj.productId === cur
    );
    const slotObj = {};
    productInventoryModelsById.forEach((t) => {
      slotObj[t.slotStructureId] = {
        id: t.slotStructureId,
        name: t.name,
        time: t.end,
      };
    });

    const slotIdNames = Object.keys(slotObj)
      .map((item) => slotObj[+item])
      .sort((a, b) => (a.time > b.time ? 1 : -1));

    return {
      ...prev,
      [cur]: {
        productInventoryModels: productInventoryModelsById,
        slotIdNames,
      },
    };
  }, {});

  times.slotIds = slots;
  return times;
};

export const getUpgrades = (cart, date, isTimeSlotRedirect) => {
  let prodId;
  let tzOffset = new Date().getTimezoneOffset() * 60000;
  const promotionIds = cart.products.map((p) => {
    const id = p.appliedPromotions.length
      ? p.appliedPromotions[0].promotionId
      : 0;
    prodId = p.productId;
    return id;
  });
  const removePromoDupes = new Set(promotionIds);
  const array = Array.from(removePromoDupes);
  const local = new Date(Date.now() - tzOffset).toISOString().slice(0, -1);
  const body = {
    promotionIds: array,
    visitDate: date,
    currentDate: local,
  };

  return (dispatch, getState) => {
    const { client, API, fullProductList } = getState();
    const headers = getHeaders(client);

    dispatch({ type: actions.SET_IS_LOADING, payload: true });

    axios
      .post(`${API}/api/kiosk/product/${prodId}/upgrades`, body, headers)
      .then((response) => {
        const { data } = response;
        const availableProductIds = fullProductList.map((item) => item.id);
        const upgradeProducts = data.filter((item) =>
          availableProductIds.includes(item.upgradeProductId)
        );
        dispatch({
          type: actions.GET_UPGRADES,
          payload: upgradeProducts.length ? upgradeProducts : null,
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: actions.GET_UPGRADES,
          payload: null,
        });
      })
      .finally(() => {
        dispatch({ type: actions.SET_IS_LOADING, payload: false });
        if (isTimeSlotRedirect) {
          dispatch(timeSlotRedirect());
        }
      });
  };
};

export const resetUpgrades = () => {
  return (dispatch) => {
    dispatch({ type: actions.RESET_UPGRADES });
  };
};

export const timeSlotRedirect = () => {
  return (dispatch, getState) => {
    const state = getState();
    const { isExchange, upgradeProducts, upgradeFlag } = state;
    if (!isExchange && upgradeProducts && !upgradeFlag) {
      return history.push("/Upgrades");
    }
    return history.push("/Cart");
  };
};

export const redirectUser = (path) => {
  return () => {
    return history.push(path);
  };
};

export const AddBocaSocket = (boca) => {
  return (dispatch) => {
    dispatch({ type: actions.BOCA_STATUS_SOCKET, payload: boca });
  };
};

export const AddTicketSocket = (ticket) => {
  return (dispatch) => {
    dispatch({ type: actions.TICKET_PRINT_SOCKET, payload: ticket });
  };
};

export const isPrinterOnline = (status) => {
  return (dispatch) => {
    dispatch({ type: actions.IS_PRINTER_ONLINE, payload: status });
  };
};

export const handleAgreeOnlyDigital = (status) => (dispatch) => {
  dispatch({ type: actions.IS_AGREED_ONLY_DIGITAL, payload: status });
};

export const saveUpgradeInfo = (ticketTime, productTo, productFrom) => {
  let upgrade = {
    UpgradeInfo: {
      upgradeFrom: `${productFrom}`,
      upgradeTo: `${productTo}`,
      visitDate: ticketTime,
      waitTime: (Date.now() - Date.parse(ticketTime)) / 60000,
    },
    upgraded: true,
  };
  return (dispatch) => {
    dispatch({ type: actions.SAVE_UPGRADE_INFO, payload: upgrade });
  };
};

export const createCityPassExchangeOrder = (barcodes) => {
  return (dispatch, getState) => {
    const { client, API, selectedDate, fullProductList, exchangeType } =
      getState();
    const { memberVenueId, salesTerminalTypeId } = getKeys(client);
    const headers = getHeaders(client);

    dispatch({ type: actions.SET_IS_LOADING, payload: true });

    const data = {
      voucherCodes: barcodes,
      barcodeProvider: exchangeType.replaceAll(" ", ""),
    };

    axios
      .post(`${API}/api/kiosk/cityPassVoucher`, data, headers)
      .then((res) => {
        const { data } = res;

        const uniqueBarcodes = data.filter(
          (elem, index) =>
            data.findIndex(
              (obj) => obj.externalBarcode === elem.externalBarcode
            ) === index
        );

        let exchCart = {
          memberVenueId,
          salesTerminalTypeId,
          kioskMachineName: "",
          products: uniqueBarcodes,
        };
        let payload = {
          exchCart,
          isExchange: true,
        };

        const { productId } = uniqueBarcodes[0];
        const newProduct = fullProductList.find((el) => el.id === productId);

        if (!newProduct) {
          dispatch(
            setError(
              "There is no available timeslot for the selected date. Please choose another date for your visit using the calendar above"
            )
          );
          dispatch({ type: actions.SET_IS_LOADING, payload: false });
          return;
        }
        dispatch(selectProduct(newProduct));
        dispatch({
          type: actions.EXCHANGE_CART,
          payload: payload,
        });
        dispatch(fetchProductAvailTimes(newProduct.id, selectedDate));
      })
      .catch((err) => {
        console.error("err\n", err);
        dispatch({ type: actions.SET_IS_LOADING, payload: false });
        dispatch(
          setError(
            "System error occurred during creating your order. Try again."
          )
        );
      });
  };
};

export const setError = (msg) => {
  const obj = { error: msg || "" };
  return (dispatch) => {
    dispatch({ type: actions.SET_ERROR, payload: obj });
  };
};

export const addExchTimeSlot = () => {
  return (dispatch, state) => {
    const { currentTime, selectedDate, exchangeCart } = state();
    const { id } = currentTime;
    const { products } = exchangeCart;
    const newExchangeCart = { ...exchangeCart };

    const selectedProdIds = Object.keys(currentTime || {}).map((id) => +id);

    const productsWithoutTime = products.map((prod) => {
      const updatedProds = { ...prod };
      updatedProds.productInvId = id;
      updatedProds.date = apiQueryDate(selectedDate, true);
      return updatedProds;
    });

    const productsTimed = selectedProdIds.reduce((array, id) => {
      const productToTimed = productsWithoutTime.map((prod) => ({
        ...prod,
        productInvId: currentTime[id].id,
      }));

      return [...array, ...productToTimed];
    }, []);

    newExchangeCart.products = productsTimed;
    dispatch({ type: actions.ADD_EXCHANGE_TIMESLOT, payload: newExchangeCart });
    dispatch(timeSlotRedirect());
  };
};

export const addTotalTicketsPrice = (price) => {
  return (dispatch) => {
    dispatch({ type: actions.SET_TOTAL_TICKETS_PRICE, payload: price });
  };
};

export const setCurrentDate = () => {
  const newDate = new Date();
  const todayDate = newDate.getDate();
  return (dispatch, getState) => {
    const { currentDate } = getState();
    const stateDate = currentDate.getDate();
    if (todayDate !== stateDate) {
      dispatch({ type: actions.SET_CURRENT_DATE, payload: newDate });
      dispatch(fetchProductsList(newDate));
    }
  };
};

export const createNewWebSocket = () => {
  return (dispatch, getState) => {
    const {
      bocaStatusUrl,
      printerSocketUrl,
      ticketPrintWebSocket,
      bocaStatusWebSocket,
    } = getState();
    const wsOnError = () => {
      setTimeout(() => {
        bocaStatusWebSocket && bocaStatusWebSocket.close();
        ticketPrintWebSocket && ticketPrintWebSocket.close();
        dispatch(createNewWebSocket());
      }, 10000);
    };

    if (
      !bocaStatusWebSocket?.checkIsAvailable() ||
      !ticketPrintWebSocket?.checkIsAvailable()
    ) {
      bocaStatusWebSocket && bocaStatusWebSocket.close();
      ticketPrintWebSocket && ticketPrintWebSocket.close();

      const boca = PrinterWebSocket(
        bocaStatusUrl,
        wsOnError,
        bocaStatusWebSocketOnMessage
      );
      boca.init();
      dispatch(AddBocaSocket(boca));

      const ticket = PrinterWebSocket(
        printerSocketUrl,
        wsOnError,
        ticketPrintWebSocketOnMessage
      );
      ticket.init();
      dispatch(AddTicketSocket(ticket));
    }
  };
};

export const setExchangeMode = (exchangeType) => {
  return (dispatch) => {
    dispatch({ type: actions.SET_EXCHANGING_MODE, payload: exchangeType });
  };
};

export const updatePaymentSocket = (payload) => (dispatch) => {
  dispatch({ type: actions.PAYMENT_SOCKET, payload });
};
