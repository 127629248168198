export const PrinterWebSocket = (url, wsOnError, wsOnMessage) => {
  let self = {};
  self.running = url.includes("ticketprint") ? true : false;

  //Private attribute
  const connectionInfo = {
    url: url,
  };
  let ws;

  //Public functions
  self.init = () => {
    ws = new WebSocket(connectionInfo.url);
    //making sure correct types are passed
    ws.onopen = () => {
      const wsType = url.includes("ticketprint") ? "Ticket Print" : "Boca";
      console.log(wsType + "WebSocket connection is opened");
    };

    ws.onclose = () => {
      self.running = false;
      sessionStorage.setItem("isPrinterOnline", false);
    };

    ws.onerror = (e) => {
      self.running = false;
      url.includes("boca") && wsOnError();
    };

    ws.onmessage = (e) => {
      if (typeof wsOnMessage === "function") {
        wsOnMessage(e);
        if (e.data === "offline") {
          self.running = false;
        }
        if (e.data === "online") {
          self.running = true;
        }
        console.log("onmessage = " + e.data);
      }
    };
  };

  self.close = () => {
    ws.close();
    self.running = false;
  };

  //not using currently
  self.reInit = () => {
    self.close();
    self.init();
  };

  self.checkIsAvailable = () => {
    return self.running;
  };

  self.send = (data) => {
    ws.send(JSON.stringify(data));
  };

  self.printTickets = (orderId, appKey) => {
    const parameter = {
      appKey,
      orderId,
    };
    ws.send(JSON.stringify(parameter));
  };

  return self;
};
