import * as actionsList from "../Actions/actions";

const initialState = {
  availTimes: {
    productInventoryModels: [],
    slotIds: [],
    slotStructPriceAdjustmentModels: [],
  },
  bocaStatusWebSocket: null,
  cart: {
    orderId: null,
    orderComment: null,
    products: [],
  },
  cartN: {
    orderId: null,
    orderComment: null,
    products: [],
  },
  currentDate: new Date(),
  currentProduct: {},
  currentProductN: {},
  currentTab: "1",
  currentTime: {},
  currentTimeN: {},
  fullProductList: [],
  isExchange: false,
  orderStatus: "no order",
  printerOnline: true,
  isAgreedOnlyDigital: false,
  isKioskAllowedToUse: true,
  productList: [],
  selectedDate: new Date(),
  ticketPrintWebSocket: null,
  upgradeFlag: false,
  upgradeProducts: null,
  isLoading: false,
  totalTicketsPrice: null,
  exchangeType: "",
  isDebugMode: false,
  paymentWebSocket: null,
};

export const reducer = (state = initialState, action) => {
  const {
    client,
    API,
    bocaStatusUrl,
    printerSocketUrl,
    ticketPrintWebSocket,
    bocaStatusWebSocket,
    viewTicketsLink,
    isOnlyDigitalFlowAllowed,
    isDebugMode,
    paymentWebSocket,
  } = state;

  const defaultState = {
    client,
    API,
    bocaStatusUrl,
    printerSocketUrl,
    ticketPrintWebSocket,
    bocaStatusWebSocket,
    viewTicketsLink,
    isOnlyDigitalFlowAllowed,
    isDebugMode,
    paymentWebSocket,
  };

  switch (action.type) {
    case actionsList.SKIP_UPGRADE:
      return { ...state, upgradeFlag: action.payload };
    case actionsList.DATECHANGE:
      return { ...state, selectedDate: action.payload };
    case actionsList.PRODUCT_SUCCESS:
      return {
        ...state,
        fullProductList: action.payload.fullProductList,
        productList: action.payload.productList,
      };
    case actionsList.PRODUCT_SELECT:
      return { ...state, currentProduct: action.payload };
    case actionsList.PRODUCT_SELECT_N:
      return { ...state, currentProductN: action.payload };
    case actionsList.PROD_CATEGORY_CHANGE:
      return {
        ...state,
        productList: action.payload.productList,
        currentTab: action.payload.currentTab,
      };
    case actionsList.TIME_SELECT:
      return { ...state, currentTime: action.payload };
    case actionsList.TIME_SELECT_N:
      return { ...state, currentTimeN: action.payload };
    case actionsList.RESET_TIME_SELECT:
      return { ...state, currentTime: initialState.currentTime };
    case actionsList.ADD_TO_CART:
      return { ...state, cart: action.payload };
    case actionsList.ADD_TO_CART_N:
      return { ...state, cartN: action.payload };
    case actionsList.PROMO_SUCCESS:
      return { ...state, promotions: action.payload };
    case actionsList.DISCOUNT_SUCCESS:
      return { ...state, discounts: action.payload };
    case actionsList.PROD_TIMES_SUCCESS:
      return { ...state, availTimes: action.payload };
    case actionsList.ORDER_SUCCESS:
      return { ...state, orderStatus: action.payload };
    case actionsList.ORDER_ERROR:
      return {
        ...state,
        orderStatus: { error: true, message: action.payload },
      };
    case actionsList.RESET_STATE:
      return {
        ...initialState,
        ...defaultState,
        currentDate: new Date(),
        selectedDate: new Date(),
      };
    case actionsList.RESET_CART:
      return {
        ...state,
        cart: initialState.cart,
        cartN: initialState.cartN,
      };
    case actionsList.RESET_ORDER:
      return { ...state, orderStatus: initialState.orderStatus };
    case actionsList.GET_UPGRADES:
      return { ...state, upgradeProducts: action.payload };
    case actionsList.RESET_UPGRADES:
      return { ...state, upgradeProducts: initialState.upgradeProducts };
    case actionsList.BOCA_STATUS_SOCKET:
      return { ...state, bocaStatusWebSocket: action.payload };
    case actionsList.TICKET_PRINT_SOCKET:
      return { ...state, ticketPrintWebSocket: action.payload };
    case actionsList.PAYMENT_SOCKET:
      return { ...state, paymentWebSocket: action.payload };
    case actionsList.IS_PRINTER_ONLINE:
      return {
        ...state,
        printerOnline: action.payload,
        isKioskAllowedToUse: action.payload || state.isAgreedOnlyDigital,
      };
    case actionsList.IS_AGREED_ONLY_DIGITAL:
      return {
        ...state,
        isAgreedOnlyDigital: action.payload,
        isKioskAllowedToUse: action.payload || state.printerOnline,
      };
    case actionsList.EXCHANGE_CART:
      return {
        ...state,
        exchangeCart: action.payload.exchCart,
        isExchange: action.payload.isExchange,
      };
    case actionsList.ADD_EXCHANGE_TIMESLOT:
      return { ...state, exchangeCart: action.payload };
    case actionsList.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case actionsList.SET_API_DATA:
      return { ...initialState, ...action.payload };
    case actionsList.SET_ERROR:
      return { ...state, ...action.payload };
    case actionsList.SET_TOTAL_TICKETS_PRICE:
      return { ...state, totalTicketsPrice: action.payload };
    case actionsList.SET_CURRENT_DATE:
      return {
        ...state,
        currentDate: action.payload,
        selectedDate: action.payload,
      };
    case actionsList.SET_EXCHANGING_MODE:
      return { ...state, exchangeType: action.payload };
    default:
      return state;
  }
};
