import React from "react";

import { Modal } from "reactstrap";
import Button from "./Button";
import {
  CheckoutPaymentErrorModalWrapper,
  PaymentErrorTitle,
} from "../Styles/ViewStyles/CheckoutStyles";

const CheckoutPaymentError = ({ client, title, retryClick, cancelClick }) => (
  <Modal isOpen={true} centered={true} style={{ maxWidth: 1000 }}>
    <CheckoutPaymentErrorModalWrapper>
      <PaymentErrorTitle>
        {title || "Transaction declined. Please try again."}
      </PaymentErrorTitle>
      <Button
        label="Use another method of payment"
        click={retryClick}
        client={client}
        width={"700px"}
        long
      />
      <Button
        label="Cancel order"
        click={cancelClick}
        client={client}
        width={"700px"}
        long
      />
    </CheckoutPaymentErrorModalWrapper>
  </Modal>
);

export default CheckoutPaymentError;
