import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Buttons from "../Components/Landing/buttonContainer";

import {
  Landing,
  PrinterUnavailable,
  TitleText,
} from "../Styles/ViewStyles/LandingStyles";
import PrinterDown from "./PrinterDown";
import {
  fetchProductsList,
  resetState,
  createNewWebSocket,
} from "../Actions/actionCreator";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      exchange: false,
    };

    this.ClickHandle = () => {
      this.setState({
        redirect: true,
      });
    };

    this.ClickForExchange = () => {
      this.setState({ exchange: true });
    };
  }

  componentDidMount() {
    const { fetchProductsList, resetState, createNewWebSocket } = this.props;
    resetState();
    createNewWebSocket();
    fetchProductsList();
  }

  componentDidUpdate(prevProps) {
    const { fetchProductsList, printerOnline } = this.props;
    if (printerOnline && prevProps.printerOnline !== printerOnline) {
      fetchProductsList();
    }
  }

  render() {
    const { client, isKioskAllowedToUse, paymentWebSocket, isDebugMode } =
      this.props;
    const { redirect, exchange } = this.state;

    if (redirect) {
      return <Redirect push to={"/ProductSelection"} />;
    }
    if (exchange) {
      return <Redirect push to={"/CityPassExchange"} />;
    }

    return (
      <Landing client={client}>
        {client === "sn" && (
          <TitleText client={client}>
            <h1>
              Recently transformed, totally transforming <br />
              <span className="largeText">THE FUTURE. NOW OPEN.</span>
            </h1>
          </TitleText>
        )}

        {!paymentWebSocket?.isOnline && (
          <PrinterUnavailable client={client}>
            <div>
              We&#39;re sorry but this kiosk is unable to process payment at
              this time.
            </div>
            <div>Please try another kiosk or the Box Office.</div>
          </PrinterUnavailable>
        )}

        {isKioskAllowedToUse ? (
          <Buttons
            click1={this.ClickHandle}
            click2={this.ClickForExchange}
            client={this.props.client}
            isPurchaseDisabled={!isDebugMode && !paymentWebSocket?.isOnline}
          />
        ) : (
          <PrinterDown />
        )}
      </Landing>
    );
  }
}

const mapStateToProps = (state) => ({
  bocaStatusWebSocket: state.bocaStatusWebSocket,
  printerOnline: state.printerOnline,
  client: state.client,
  printerSocketUrl: state.printerSocketUrl,
  isKioskAllowedToUse: state.isKioskAllowedToUse,
  paymentWebSocket: state.paymentWebSocket,
  isDebugMode: state.isDebugMode,
});

const mapDispatchToProps = {
  fetchProductsList,
  resetState,
  createNewWebSocket,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
