export const UAT_DEVICE_DATA = {
  UniqueDeviceId: "00000999",
  PaymentDeviceIP: "0.0.0.0",
  MachineName: "KIOSK-999",
};

const CARD_EXPIRED = "Card has expired";

export const PAYMENT_ERRORS_BY_CODE = {
  54: CARD_EXPIRED,
  101: CARD_EXPIRED,
};

export const PAYMENT_ERRORS_BY_MESSAGE = {
  "EXPIRED CARD": CARD_EXPIRED,
};
