import styled, { keyframes } from "styled-components";

import { getStyle } from "../../Utils/Helpers/getStyleOrResources";

const slideInFromLeft = keyframes`
    from { transform: translateX(-100%) }
    
`;
const slideInFromRight = keyframes`
    from { transform: translateY(900%)}
`;

export const Landing = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100%;
  padding-left: 13%;

  button {
    animation: ${slideInFromRight} 1s ease-in-out;
  }
`;

export const Image = styled.img`
  max-width: 65%;
  height: auto;
  margin-bottom: 2%;
  animation: ${slideInFromLeft} 1s ease;
`;

export const Welcome = styled.div`
  width: 520px;
  text-align: center;
  font-size: 90px;
  transition: opacity 400ms;
  font-family: ${(props) => getStyle(props.client).altFont2 + "!important"};
  color: #fbfbfb;
`;

export const TitleText = styled.div`
  margin-bottom: 100px;
  color: white;

  h1 {
    font-family: ${(props) =>
      getStyle(props.client).altFont
        ? getStyle(props.client).altFont
        : getStyle(props.client).font};
    font-style: italic;
  }

  .largeText {
    font-family: ${(props) => getStyle(props.client).boldFont};
    font-size: 1.4em;
  }

  animation: ${slideInFromLeft} 1s ease;
`;

export const PrinterUnavailable = styled.div`
  margin: -50px 0 50px 0;
  max-width: 800px;
  padding: 20px;
  font-size: 32px;
  color: ${(props) => getStyle(props.client).secondary};
  background-color: rgba(255, 255, 255, 0.8);
`;
